import Vue from "vue";
import axios from "axios";

export default {
  getpublicportfolio: function (editToken) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/publicportfolios/resumes/${editToken}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  updateportfolio: function (data, id, token) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/publicportfolios/${id}/?recaptchaResponse=${token}`,
          data
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  getimpacts: function (editToken) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/publicportfolios/resumes/impacts/${editToken}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
};
