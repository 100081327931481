<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile-nologo'">
    <v-container fill-height>
      <v-row class="ma-0 pa-0" justify="center">
        <v-card
          outlined
          :width="desktop ? '50%' : '100%'"
          :max-width="desktop ? '' : '350px'"
          :class="'complete-card mb-5 ' + (mobile ? '' : 'mt-10')"
        >
          <v-row class="ma-0 pa-0">
            <v-col class="pa-8">
              <div>
                <span :class="desktop ? 'text-title' : 'mobile-text-title'" class="black-text"
                  >Evalúa tu experiencia
                </span>
              </div>

              <div class="main-text">
                Estimados {{ portfolio.clientBusinessName }}, en Tway, plataforma especializada en Transformación
                Digital, estamos evaluando a {{ portfolio.specialistName }} como proveedor de servicios digitales.
                Cuéntanos tu satisfacción sobre el trabajo realizado con ustedes en el proyecto
                {{ portfolio.projectName }} ({{ moment(portfolio.startDate).format("yyyy") }}).
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <v-row class="ma-0 pa-0" justify="center">
        <v-card outlined :width="desktop ? '50%' : '100%'" :max-width="desktop ? '' : '350px'" class="complete-card">
          <v-row class="ma-0 pa-0">
            <v-col class="px-8">
              <v-form ref="form">
                <v-card-text>
                  <v-row justify="start" class="mb-6 subtitle-1" style="line-height: 27px">
                    <div>Marca de 1 a 5 estrellas los siguientes criterios</div>
                  </v-row>

                  <v-row justify="space-between">
                    <div class="roboto black-text mt-4">Plazo de Entrega</div>

                    <div>
                      <v-rating
                        v-model="term"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        length="5"
                        hover
                        size="30"
                      />
                    </div>
                  </v-row>
                  <v-row justify="space-between">
                    <div class="roboto black-text mt-4">Calidad Técnica</div>

                    <div>
                      <v-rating
                        v-model="technicalQuality"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        length="5"
                        hover
                        size="30"
                      />
                    </div>
                  </v-row>
                  <v-row justify="space-between">
                    <div class="roboto black-text mt-4">Cumplimiento de los Requisitos Formales</div>

                    <div>
                      <v-rating
                        v-model="requirements"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        length="5"
                        hover
                        size="30"
                      />
                    </div>
                  </v-row>
                  <v-row justify="space-between">
                    <div class="roboto black-text mt-4">Cumplimiento Presupuesto</div>

                    <div>
                      <v-rating
                        v-model="budget"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        length="5"
                        hover
                        size="30"
                      />
                    </div>
                  </v-row>
                  <v-row justify="space-between">
                    <div class="roboto black-text mt-4">Soporte durante proyecto</div>

                    <div>
                      <v-rating
                        v-model="support"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        length="5"
                        hover
                        size="30"
                      />
                    </div>
                  </v-row>

                  <div class="mt-8">
                    <v-row class="subtitle-1"> Selecciona el o los impactos del proyecto en tu negocio </v-row>
                    <v-row>
                      <v-select
                        ref="choice"
                        v-model="selectedImpacts"
                        :items="impacts"
                        item-text="name"
                        item-id="id"
                        item-color="success"
                        attach
                        chips
                        label="Selecciona aquí"
                        multiple
                        return-object
                        @input="limiter"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0 || index === 1 || index === 2">
                            <span v-if="item.name"
                              >{{ item.name.substring(0, 13) }} <span v-if="item.name.length > 13">..</span></span
                            >
                          </v-chip>
                          <span v-if="index === 3" class="grey--text caption"> (+{{ test.impacts.length - 3 }}) </span>
                        </template>
                      </v-select>
                    </v-row>
                    <span v-if="selectedImpacts.length" class="select-subtitle roboto"
                      >{{ selectedImpacts.length }} impactos seleccionados</span
                    >
                    <span v-if="!selectedImpacts.length && !checkboxImpacts" style="color: #999999" class="roboto">
                      Marque un máximo de 3 Impactos
                    </span>
                    <span v-if="!selectedImpacts.length && checkboxImpacts" class="select-subtitle roboto"
                      >0 impactos seleccionados</span
                    >
                  </div>
                  <v-checkbox v-model="checkboxImpacts" color="success" :label="'Ninguno de los anteriores'" />
                  <div class="text-center black-text mt-5 roboto" style="font-size: 16px; line-height: 24px">
                    <div>
                      En general, ¿La empresa
                      {{ portfolio.clientBusinessName }} cumplió con las expectativas de
                      {{ portfolio.specialistName }} ?
                    </div>
                    <v-rating
                      v-model="general"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$ratingFull"
                      length="5"
                      hover
                      size="30"
                    />
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-col class="text-center" v-if="desktop">
                    <v-btn
                      color="deep-purple accent-4"
                      class="roboto text-right roboto mr-6"
                      text
                      @click="modalConfirm = true"
                    >
                      SALIR SIN EVALUAR
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      color="#16C62E"
                      class="elevation-2 pa-4 white--text roboto"
                      @click="updateportfolio()"
                    >
                      ENVIAR EVALUACIÓN
                    </v-btn>
                  </v-col>
                  <v-col v-if="mobile">
                    <v-row justify="center">
                      <v-btn
                        large
                        rounded
                        color="#16C62E"
                        class="elevation-2 white--text roboto mb-6"
                        @click="updateportfolio()"
                      >
                        ENVIAR EVALUACIÓN
                      </v-btn>
                    </v-row>
                    <v-row justify="center">
                      <v-btn
                        color="deep-purple accent-4"
                        class="roboto text-right roboto"
                        text
                        @click="modalConfirm = true"
                      >
                        SALIR SIN EVALUAR
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-card-actions>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <v-dialog v-model="modalConfirm" max-width="500">
        <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
          <v-card-title
            class="headline mb-3"
            :class="!desktop ? 'pt-8' : ''"
            :style="!desktop ? 'font-size: 20px !important;' : ''"
          >
            <v-row class="ma-0 pa-0" justify="center">
              <span class="text-center modal-title">¿Realmente quieres salir sin evaluar?</span>
            </v-row>
          </v-card-title>

          <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
            <v-row class="ma-0 pa-0 text-center" justify="center">
              <span class="text-center modal-text">
                Si te vas sin evaluar el proyecto que realizaste junto a esta empresa, tienes la oportunidad de volver
                al correo y realizar la evaluación acá.
              </span>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-col v-if="desktop" class="text-center">
              <a
                class="tway-violet--text roboto"
                v-if="desktop"
                :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                @click.stop="modalConfirm = false"
              >
                CANCELAR
              </a>
              <v-btn
                v-if="desktop"
                rounded
                color="tway-violet"
                class="elevation-2 pa-6 white--text roboto"
                @click="close()"
              >
                SI, SALIR SIN EVALUAR
              </v-btn>
            </v-col>
            <v-row v-if="!desktop" class="ma-0 pa-0" justify="center">
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-btn rounded color="tway-violet" class="elevation-2 pa-6 white--text roboto" @click="close()">
                    SI, SALIR SIN EVALUAR
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <a
                    class="tway-violet--text roboto"
                    v-if="!desktop"
                    :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                    @click.stop="modalConfirm = false"
                  >
                    CANCELAR
                  </a>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import publicPortfolioService from "@/services/publicPortfolioService";
import moment from "moment";

export default {
  name: "EvaluationClient",
  data() {
    return {
      modalConfirm: false,
      breafcaseId: "",
      editToken: "",

      moment: moment,
      impacts: [],
      selectedImpacts: [],
      test: {
        impacts: [],
      },
      technicalQuality: 0,
      requirements: 0,
      budget: 0,
      support: 0,
      term: 0,
      general: 0,
      portfolio: {
        specialistId: "",
        clientBusinessName: "",
      },
      checkboxImpacts: false,
    };
  },

  created: function () {
    this.editToken = this.$route.params.id;
    publicPortfolioService
      .getpublicportfolio(this.$route.params.id)
      .then((briefcase) => {
        this.portfolio = briefcase;
        this.getImpacts(this.editToken);
      })
      .catch((err) => {
        this.$log.error(err);
        this.$router.push({ name: "404" });
      });

    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$recaptchaInstance.hideBadge();
    next();
  },

  methods: {
    close() {
      this.$router.push({ name: "404" });
    },
    getImpacts(editToken) {
      publicPortfolioService
        .getimpacts(editToken)
        .then((impacts) => {
          this.impacts = impacts;
        })
        .catch((err) => {
          this.$log.error(err);
          this.$router.push({ name: "404" });
        });
    },
    limiter(e) {
      if (e.length > 3) {
        e.pop();
      }
    },
    async updateportfolio() {
      if (
        this.general > 0 &&
        this.technicalQuality > 0 &&
        this.requirements > 0 &&
        this.budget > 0 &&
        this.support > 0 &&
        this.term > 0
      ) {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("update");
        let data = {
          clientRate:
            (this.technicalQuality * 0.3 +
              this.requirements * 0.25 +
              this.budget * 0.2 +
              this.support * 0.15 +
              this.term * 0.1) *
              0.7 +
            this.general * 0.3,
          impacts: this.selectedImpacts,
        };
        publicPortfolioService
          .updateportfolio(data, this.portfolio.id, token)
          .then(() => this.$router.push({ name: "succesful-evaluation" }))
          .catch((err) => this.$log.error("Unexpected error: ", err));
      }
    },
  },

  watch: {
    checkboxImpacts() {
      if (this.checkboxImpacts) {
        this.selectedImpacts = [];
      }
    },
    selectedImpacts() {
      if (this.selectedImpacts.length > 0) {
        this.checkboxImpacts = false;
      }
    },
  },

  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-align: center;
}
.modal-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height, or 136% */

  text-align: center;
}
.profile-content-box {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.small-checkbox {
  margin-top: 0px;
  padding-top: 0px;
}

.small-checkbox > div > div {
  align-items: start;
}

.text-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}

.mobile-text-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}
.main-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.black-text {
  color: #333333;
}
</style>
